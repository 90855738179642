<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-layout row wrap v-else>
            <v-flex xs12>
               <h1> {{ $store.getters.language.data.purchases.report_title }}</h1>
            </v-flex> 
            <v-flex xs12 >
               <h3> {{ $store.getters.language.data.purchases.total }} : {{total}}  </h3>
            </v-flex>
            <!-- <v-flex xs12 v-if="groups.length"> -->
            
                <!-- <table style="width: 100%;" class="text-center">
                    <thead>
                        <tr>
                            <th class="py-1"> # </th>
                            <th class="py-1"> {{ $store.getters.language.data.incomes.money_move_type_name }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.total }} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in groups" :key="item.money_move_type_id">
                            <td> {{ index + 1 }} </td>
                            <td> {{ item.money_move_type_name }} </td>
                            <td> {{ (item.total).toLocaleString() }} </td>
                        </tr>
                    </tbody>
                </table> -->
            <!-- </v-flex> -->

            <!-- <v-flex xs12>
                <h2> {{ $store.getters.language.data.total }}: {{ (total).toLocaleString() }} </h2>
            </v-flex> -->

            <v-flex xs12>
                <table style="width: 100%;" class="text-center">
                    <thead>
                        <tr>
                            <th class="py-1"> # </th>
                            <th class="py-1"> {{ $store.getters.language.data.items.item_name }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.purchases.purchase_price_one }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.purchases.purchase_qty }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.purchases.purchase_price }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.purchases.purchase_date }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.incomes.user_name }} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in rows" :key="item.purchase_id">
                            <td> {{ index + 1 }} </td>
                            <td> {{ item.item_name }} </td>
                            <td> {{ (item.purchase_price).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
                            <td> {{ item.purchase_qty }} </td>
                            <td> {{ (item.purchase_total_price).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
                            <td> {{ new Date(item.purchase_date).toISOString().split('T')[0] }} </td>
                            <!-- <td> {{ item.money_move_type_name }} </td> -->
                            <td> {{ item.user_name }} </td>
                        </tr>
                    </tbody>
                </table>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import requests from '../../../requests/reports.request'
export default {
    props: {
        query: Object
    },
    data() {
        return {
            rows: [],
            loading: true,
            groups: [],
            total: 0
        }
    },
    mounted() {
        var q = 'store_id=' + this.$store.getters.store.store_id;
        const arr = Object.entries(this.query);
        if(arr.length > 0) {
            for(var item of arr) {
                if(item[1] && item[1] != "null" && item != '') {
                    q += '&' + item[0] + '=' + item[1];
                }
            }
        }
        requests.purchasesReport(q).then(r => {
            this.rows = r.data.rows;
            this.groups = r.data.groups;
            this.total = r.data.total;
            this.loading = false;
        })
    }
}
</script>


<style scoped>
    table {
        border: 1px solid;
        border-collapse: collapse;
    }
    tr {
        border: 1px solid;
    }
    td, th {
        border: 1px solid;
    }
</style>
