<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-layout row wrap v-else>
            <v-flex xs12>
                {{ $store.getters.language.data.accounts.report_title }}
            </v-flex>
            <v-flex xs12>
                <table style="width: 100%;" class="text-center">
                    <thead>
                        <tr>
                            <th class="py-1"> # </th>
                            <th class="py-1"> {{ $store.getters.language.data.accounts.account_name }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.accounts.account_phone }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.accounts.account_type }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.accounts.account_is_default }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.accounts.total_balance }} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in rows" :key="item.account_id">
                            <td> {{ index + 1 }} </td>
                            <td> {{ item.account_name }} </td>
                            <td> {{ item.account_phone }} </td>
                            <td> {{ item.account_type | account_type_filter }} </td>
                            <td> {{ item.account_is_default | account_is_default_filter }} </td>
                            <td> <a @click.prevent="getAccountBalance(item.account_id)"> {{ (item.total_balance).toLocaleString() }}  {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }}  </a> </td>
                        </tr>
                    </tbody>
                </table>
            </v-flex>
        </v-layout>
        <v-dialog v-model="detail_dialog" max-width="1000">
            <v-card>
                <v-card-title>
                    {{ $store.getters.language.data.accounts.account_balance_detail }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-8">
                    <v-layout row wrap>
                        <v-flex xs12>
                            <table style="width: 100%;" class="text-center">
                                <thead>
                                    <tr>
                                        <th class="py-1"> # </th>
                                        <th class="py-1"> {{ $store.getters.language.data.accounts.income_amount }} </th>
                                        <th class="py-1"> {{ $store.getters.language.data.accounts.expense_amount }} </th>
                                        <th class="py-1"> {{ $store.getters.language.data.accounts.purchase_amount }} </th>
                                        <th class="py-1"> {{ $store.getters.language.data.accounts.total_invoice }} </th>
                                        <!-- <th class="py-1"> {{ $store.getters.language.data.accounts.loan_amount }} </th> -->
                                        <th class="py-1"> {{ $store.getters.language.data.accounts.sended_amount }} </th>
                                        <th class="py-1"> {{ $store.getters.language.data.accounts.recived_amount }} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in rows_detail" :key="item.expense_id">
                                        <td> {{ index + 1 }} </td>
                                        <td> <a @click.prevent="openAccountDetail('incomes')"> {{ ((item.income_amount|| 0).toLocaleString())  }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </a> </td>
                                        <td> <a @click.prevent="openAccountDetail('expenses')"> {{ (item.expense_amount|| 0).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </a> </td>
                                        <td> <a @click.prevent="openAccountDetail('purchases')"> {{ (item.purchase_price_total|| 0).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </a> </td>
                                        <td> <a @click.prevent="openAccountDetail('invoices')"> {{ (item.total_invoice|| 0).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </a> </td>
                                        <!-- <td> <a @click.prevent="openAccountDetail('loans')"> {{ (item.loan_amount|| 0).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </a> </td> -->
                                        <td> <a @click.prevent="openAccountDetail('sended')"> {{ (item.sended_amount|| 0).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </a> </td>
                                        <td> <a @click.prevent="openAccountDetail('received')"> {{ (item.recived_amount|| 0).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </a> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import requests from '../../../requests/reports.request'
export default {
    props: {
        query: Object
    },
    data() {
        return {
            rows: [],
            loading: true,
            rows_detail: [],
            selected_account_id: null,
            detail_dialog: false
        }
    },
    mounted() {
        this.loading = true;
        var q = 'store_id=' + this.$store.getters.store.store_id;
        const arr = Object.entries(this.query);
        if(arr.length > 0) {
            for(var item of arr) {
                if(item[1] && item[1] != "null" && item != '') {
                    q += '&' + item[0] + '=' + item[1];
                }
            }
        }
        requests.accountsWithTotalBalances(q).then(r => {
            this.rows = r.data.rows;
            this.loading = false;
        })
    },
    methods: {
        getAccountBalance(account_id) {
            this.rows_detail = [];
            this.detail_dialog = true;
            requests.accountBalance(`account_id=${account_id}`).then(r => {
                this.selected_account_id = account_id;
                this.rows_detail = r.data.rows;
                console.log("--------");

                console.log(r.data.rows);
                console.log("--------");
            })
        },
        openAccountDetail(type) {
            window.open('/reports/account_detail/' + this.selected_account_id + '/' + type);
        }
    },
    filters: {
        account_type_filter(value) {
            return {
                store: 'Store',
                out_store: 'Out Store'
            }[value]
        },
        account_is_default_filter(value) {
            return {
                true: 'Yes',
                false: 'No'
            }[value]
        },
    }
}
</script>

<style scoped>
    table {
        border: 1px solid;
        border-collapse: collapse;
    }
    tr {
        border: 1px solid;
    }
    td, th {
        border: 1px solid;
    }
</style>

