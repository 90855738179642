<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-layout row wrap v-else>
			<v-flex xs12>
				<h2 class="my-2">جەردی مەخزەن</h2>
				<h2 class="my-2">{{ $store.getters.language.data.items.total_items_price }} : {{ (total.total_amount ||0).toLocaleString() }}</h2>

			</v-flex>
			<v-flex xs12>
				<table style="width: 100%;" class="text-center">
					<thead>
						<tr>
							<th class="py-1"> # </th>
							<th class="py-1"> {{ $store.getters.language.data.invoice_items.item_name }} </th>
							<th class="py-1"> {{ $store.getters.language.data.items.item_available_qty }} </th>
							<th class="py-1"> {{ $store.getters.language.data.items.total_amount_per_item1 }} </th>
							<th class="py-1"> {{ $store.getters.language.data.items.total_amount_per_item }} </th>
							<th class="py-1"> {{ $store.getters.language.data.items.item_expire_date }} </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in rows" :key="item.invoice_item_id">
							<td> {{ index + 1 }} </td>
							<td> {{ item.item_name }} </td>
							<td> {{ item.item_available_qty }} {{ item.item_unit }} </td>
							<td> {{ (item.item_sell_price_retail|| 0).toLocaleString()  }} </td>
							<td> {{ (item.total_amount_per_item|| 0).toLocaleString() }} </td>
							<td v-if="item.item_expire_date">
                                <span :class="{'red--text': differenceInDays(item.item_expire_date) <= 45}">
                                    {{ new Date(item.item_expire_date).toISOString().split('T')[0]}} 
                                </span>
                            </td>
							<td v-else>-</td>
						</tr>
					</tbody>
				</table>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
	import requests from '../../../requests/reports.request'
    import moment from 'moment'
	export default {
		props: {
			query: Object
		},
		data() {
			return {
				loading: true,
				rows: [],
                total:{}
			}
		},
		mounted() {
			var q = 'store_id=' + this.$store.getters.store.store_id;
			const arr = Object.entries(this.query);
			if (arr.length > 0) {
				for (var item of arr) {
					if (item[1] && item[1] != "null" && item != '') {
						q += '&' + item[0] + '=' + item[1];
					}
				}
			}
			requests.inventoryReport(q).then(r => {
				this.rows = r.data.rows;
                this.total = r.data.total;
				this.loading = false;
			})
		},
        methods: {
            differenceInDays(date1) {
				var start = moment((new Date().toISOString().split('T')[0]), "YYYY-MM-DD");
				var end = moment(date1, "YYYY-MM-DD");
				return moment.duration(end.diff(start)).asDays()
			},
        },
	}
</script>

<style scoped>
	table {
		border: 1px solid;
		border-collapse: collapse;
	}
	tr {
		border: 1px solid;
	}
	td,
	th {
		border: 1px solid;
	}
</style>