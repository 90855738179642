<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-layout row wrap v-else>
			<v-flex xs12>
				<h2> {{ $store.getters.language.data.invoice_items.report_title }}
				</h2>
            <v-divider class="my-1"></v-divider>
			</v-flex>
			<v-flex xs12>
				<h3 class="my-1">{{ $store.getters.language.data.invoice_items.cost_price }} : {{(total.cost_price || 0).toLocaleString()}}</h3>
				<h3 class="my-1"> {{ $store.getters.language.data.invoice_items.total_price }} : {{(total.total_price || 0).toLocaleString()}}</h3>
				<h3 class="my-1"> {{ $store.getters.language.data.invoice_items.total_profit }} : {{((total.total_price-total.cost_price) || 0).toLocaleString()}}</h3>
			</v-flex>
			<v-flex xs12>
				<table style="width: 100%;" class="text-center">
					<thead>
						<tr>
							<th class="py-1"> # </th>
							<th class="py-1"> {{ $store.getters.language.data.invoice_items.item_name }} </th>
							<th class="py-1"> {{ $store.getters.language.data.categories.category_name }} </th>
							<th class="py-1"> {{ $store.getters.language.data.invoice_items.total_qty }} </th>
							<th class="py-1"> {{ $store.getters.language.data.invoice_items.cost_price }} </th>
							<th class="py-1"> {{ $store.getters.language.data.invoice_items.total_price }} </th>
							<th class="py-1"> {{ $store.getters.language.data.invoice_items.total_profit }} </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in rows" :key="item.expense_id">
							<td> {{ index + 1 }} </td>
							<td> {{ item.item_name }} </td>
							<td> {{ item.category_name}} </td>
							<td> {{ (item.total_qty || 0).toLocaleString() }} {{item.item_unit}} </td>
							<td> {{ (item.cost_price || 0).toLocaleString() }} </td>
							<td> {{ (item.total_price || 0).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
							<td> {{ ((item.total_price-item.cost_price)|| 0).toLocaleString() }} </td>
						</tr>
					</tbody>
				</table>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
	import requests from '../../../requests/reports.request'
	export default {
		props: {
			query: Object
		},
		data() {
			return {
				rows: [],
				loading: true,
				total: {}
			}
		},
		mounted() {
			var q = 'store_id=' + this.$store.getters.store.store_id;
			const arr = Object.entries(this.query);
			if (arr.length > 0) {
				for (var item of arr) {
					if (item[1] && item[1] != "null" && item != '') {
						q += '&' + item[0] + '=' + item[1];
					}
				}
			}
			requests.invoiceItemsTotalReport(q).then(r => {
				this.rows = r.data.rows;
				this.total = r.data.total;
				this.loading = false;
			})
		}
	}
</script>

<style scoped>
	table {
		border: 1px solid;
		border-collapse: collapse;
	}
	tr {
		border: 1px solid;
	}
	td,
	th {
		border: 1px solid;
	}
</style>