<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-layout row wrap v-else>
			<v-flex xs12>
				<h2 class="my-2">{{ $store.getters.language.data.balance.title }}</h2>
				<!-- <p>
                    {{total}}
				</p> -->
			</v-flex>
			<v-flex xs12>
				<table style="width: 100%;" class="text-center">
					<thead>
						<tr>
							<th class="py-1"> {{ $store.getters.language.data.balance.type }} </th>
							<th class="py-1"> {{ $store.getters.language.data.balance.price }} </th>
						</tr>
					</thead>
					<tbody>
						<!-- <tr>
							<td> 
								رصیدی هەژمارەکان
							</td>
							<td>{{ $store.getters.language.data.balance.total_received }}</td>
							<td>{{(total.receiverAmount || 0 ).toLocaleString()}}</td>
						</tr> -->
						<tr>
							<td>
								خەرجیەکان
							</td>
							<td> {{(total.expensesAmount || 0 ).toLocaleString()}}</td>
						</tr>
						<tr>
							<td>
								داهاتەکان
							</td>
							<td>{{(total.incomesAmount || 0 ).toLocaleString()}}</td>
						</tr>
						<!-- <tr>
							<td>رصیدی مەخزەن</td>
							<td>{{(total.storeBalance || 0 ).toLocaleString()}}</td>
						</tr> -->
						<tr>
							<td>
								فرۆشتن
							</td>
							<td>{{(total.invoiceAmount || 0 ).toLocaleString()}}</td>
						</tr>
						<!-- <tr>
							<td>{{ $store.getters.language.data.balance.total_purchase }}</td>
							<td>{{(total.purchasesAmount || 0 ).toLocaleString()}}</td>
						</tr> -->
						<tr>
							<td>  
								کڕین
							</td>
							<td>{{(total.purchasesAmount || 0 ).toLocaleString()}}</td>
						</tr>
						<tr>
							<td> قازانج</td>
							<td>{{(parseFloat(total.profitAmount) || 0 ).toLocaleString()}}
							</td>
						</tr>
						<th></th>
						<tr >
							<td>
								بامۆک
							</td>
							<td>
								{{(total.bamok || 0 ).toLocaleString()}}
							</td>
						</tr>
						<th></th>
                        <!-- <tr>
                            {{ $store.getters.language.data.balance.total_income }} + {{ $store.getters.language.data.balance.total_expense }} - {{ $store.getters.language.data.balance.total_purchase }} - {{ $store.getters.language.data.balance.total_send }} - {{ $store.getters.language.data.balance.total_received }} + {{ $store.getters.language.data.balance.total_invoice }}
                        </tr> -->
                        <!-- <tr>
                            {{(total.incomesAmount || 0 ).toLocaleString()}} + {{(total.expensesAmount || 0 ).toLocaleString()}} - {{(total.purchasesAmount || 0 ).toLocaleString()}} - {{(total.sendAmount || 0 ).toLocaleString()}} - {{(total.receiverAmount || 0 ).toLocaleString()}} + {{(total.invoiceAmount || 0 ).toLocaleString()}}
                        </tr> -->
						<!-- <tr>
							<td>صندوقی نەقدی</td>
							
							<td>{{(total.total || 0 ).toLocaleString()}}</td>
						</tr> -->
					</tbody>
				</table>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
	import requests from '../../../requests/reports.request'
	export default {
		props: {
			query: Object
		},
		data() {
			return {
				loading: true,
				rows: [],
				total: {
                    expensesAmount: 0,
                    incomesAmount: 0,
                    purchasesAmount: 0,
                    sendAmount: 0,
                    receiverAmount: 0,
                    invoiceAmount: 0,
                    profitAmount: 0,
					bamok: 0,
                }
			}
		},
		mounted() {
			this.loading = true;
			var q = 'store_id=' + this.$store.getters.store.store_id;
			const arr = Object.entries(this.query);
			if (arr.length > 0) {
				for (var item of arr) {
					if (item[1] && item[1] != "null" && item != '') {
						q += '&' + item[0] + '=' + item[1];
					}
				}
			}
			requests.totalBalanceReport(q).then(r => {
				if (!r.data) {
					this.loading = false;
					return
				}
				this.rows = r.data.rows;
				this.total.expensesAmount = r.data.expensesAmount.total_expense;
				this.total.incomesAmount = r.data.incomesAmount.total_incomes;
				this.total.purchasesAmount = r.data.purchasesAmount.total_purchases;
				this.total.sendAmount = r.data.sendAmount.total_send_amount;
				this.total.receiverAmount = r.data.receiverAmount.total_receiver_amount;
				this.total.invoiceAmount = r.data.invoiceAmount.total_invoice_amount;
				this.total.profitAmount = r.data.profitAmount.total_profit_amount;
                this.total.total= r.data.totalAmount;
				this.bamok = r.data.bamok.account_total_balance
                this.loading = false;
			}

			)
		}
	}
</script>

<style scoped>
	table {
		border: 1px solid;
		border-collapse: collapse;
	}
	tr {
		border: 1px solid;
	}
	td,
	th {
		border: 1px solid;
	}
</style>