<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-layout row wrap v-else>
            <v-flex xs12>
                {{ $store.getters.language.data.invoice_items.report_title }}
            </v-flex>
            <v-flex xs12>
                <table style="width: 100%;" class="text-center">
                    <thead>
                        <tr>
                            <th class="py-1"> # </th>
                            <th class="py-1"> {{ $store.getters.language.data.invoice_items.item_name }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.invoice_items.invoice_id }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.invoice_items.invoice_date }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.invoice_items.customer_name }} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in rows" :key="item.invoice_item_id">
                            <td> {{ index + 1 }} </td>
                            <td> {{ item.item_name }} </td>
                            <td>  <a @click.prevent="openInvoice(item.invoice_id)"> {{ item.invoice_id }} </a></td>
                            <td> {{ new Date(item.invoice_date).toISOString().split('T')[0] }} </td>
                            <td> {{ item.customer_name }} </td>
                        </tr>
                    </tbody>
                </table>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import requests from '../../../requests/reports.request'
export default {
    props: {
        query: Object
    },
    data() {
        return {
            rows: [],
            loading: true,
        }
    },
    mounted() {
        var q = 'store_id=' + this.$store.getters.store.store_id;
        const arr = Object.entries(this.query);
        if(arr.length > 0) {
            for(var item of arr) {
                if(item[1] && item[1] != "null" && item != '') {
                    q += '&' + item[0] + '=' + item[1];
                }
            }
        }
        requests.invoiceItemsReport(q).then(r => {
            this.rows = r.data.rows;
            this.loading = false;
        })
    },
     methods: {
        openInvoice(id) {
            window.open(`/pos/${id}`, '_blank')
        },
    }
}
</script>

<style scoped>
    table {
        border: 1px solid;
        border-collapse: collapse;
    }
    tr {
        border: 1px solid;
    }
    td, th {
        border: 1px solid;
    }
</style>