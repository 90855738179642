<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-layout row wrap v-else>
            <v-flex xs12>
                <h2> {{ $store.getters.language.data.total }}: {{ (total).toLocaleString() }} </h2>
            </v-flex>
            <v-flex xs12>
                {{ $store.getters.language.data.loans.report_title }}
            </v-flex>
            <v-flex xs12>
                <table style="width: 100%;" class="text-center">
                    <thead>
                        <tr>
                            <th class="py-1"> # </th>
                            <th class="py-1"> {{ $store.getters.language.data.loans.loan_date }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.loans.customer_name }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.loans.loan_amount }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.loans.account_name }} </th>
                            <th class="py-1"> {{ $store.getters.language.data.loans.user_name }} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in rows" :key="item.loan_id">
                            <td> {{ index + 1 }} </td>
                            <td> {{ new Date(item.loan_date).toISOString().split('T')[0] }} </td>
                            <td> {{ item.customer_name }} </td>
                            <td> {{ (item.loan_amount).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
                            <td> {{ item.account_name }} </td>
                            <td> {{ item.user_name }} </td>
                        </tr>
                    </tbody>
                </table>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import requests from '../../../requests/reports.request'
export default {
    props: {
        query: Object
    },
    data() {
        return {
            rows: [],
            loading: true,
            total: 0
        }
    },
    mounted() {
        var q = 'store_id=' + this.$store.getters.store.store_id;
        const arr = Object.entries(this.query);
        if(arr.length > 0) {
            for(var item of arr) {
                if(item[1] && item[1] != "null" && item != '') {
                    q += '&' + item[0] + '=' + item[1];
                }
            }
        }
        requests.loansReport(q).then(r => {
            this.rows = r.data.rows;
            this.total = r.data.total;
            this.loading = false;
        })
    }
}
</script>

<style scoped>
    table {
        border: 1px solid;
        border-collapse: collapse;
    }
    tr {
        border: 1px solid;
    }
    td, th {
        border: 1px solid;
    }
</style>