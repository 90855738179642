<template>
	<div>
		<v-flex xs12 lg12 sm12 xl12 class="only-print text-center">
			<img :src="banner" style="width:10%">
		</v-flex>
		<v-container class="no-print">
			<v-layout row wrap class="py-2">
				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1" v-if="[1, 2, 4, 5, 6, 7, 8, 11].includes(Number($route.params.id))">
					<v-text-field outlined dense type="date" label="From" v-model="query.from"></v-text-field>
				</v-flex>
				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1" v-if="[1, 2, 4, 5, 6, 7, 8, 11].includes(Number($route.params.id))">
					<v-text-field outlined dense type="date" label="To" v-model="query.to"></v-text-field>
				</v-flex>
				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1" v-if="[5,13].includes(Number($route.params.id))">
					<v-text-field outlined dense type="date" label="From Paid" v-model="query.from_paid"></v-text-field>
				</v-flex>
				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1" v-if="[5,13].includes(Number($route.params.id))">
					<v-text-field outlined dense type="date" label="To Paid" v-model="query.to_paid"></v-text-field>
				</v-flex>
				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1" v-if="[5, 7].includes(Number($route.params.id))">
					<v-text-field outlined dense type="text" :label="$store.getters.language.data.invoices.invoice_id" v-model="query.invoice_id"></v-text-field>
				</v-flex>
				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1" v-if="[5].includes(Number($route.params.id))">
					<v-select outlined dense :label="$store.getters.language.data.qist.qist_status" clearable :items="['paid', 'not_paid']" v-model="query.qist_status"></v-select>
				</v-flex>
				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1" v-if="[1, 2, 3, 4, 5, 6, 11,12].includes(Number($route.params.id))">
					<v-select outlined dense :label="$store.getters.language.data.accounts.account_name" clearable :items="accounts" item-text="account_name" item-value="account_id" v-model="query.account_id"></v-select>
				</v-flex>
				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1" v-if="[1, 2].includes(Number($route.params.id))">
					<v-select outlined dense :label="$store.getters.language.data.money_move_types.money_move_type_name" clearable :items="money_move_types" item-text="money_move_type_name" item-value="money_move_type_id" v-model="query.money_move_type_id"></v-select>
				</v-flex>
				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1" v-if="[1, 2, 4, 5, 6,13].includes(Number($route.params.id))">
					<v-select outlined dense :label="$store.getters.language.data.users.user_name" clearable :items="users" item-text="user_name" item-value="user_id" v-model="query.user_id"></v-select>
				</v-flex>
				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1" v-if="[4, 5, 6, 9].includes(Number($route.params.id))">
					<v-select outlined dense :label="$store.getters.language.data.customers.customer_name" clearable :items="customers" item-text="customer_name" item-value="customer_id" v-model="query.customer_id"></v-select>
				</v-flex>
				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1" v-if="[ 4, 5,7,8].includes(Number($route.params.id))">
					<v-select outlined dense :label="$store.getters.language.data.categories.category_name" clearable :items="categories" item-text="category_name" item-value="category_id" v-model="query.category_id"></v-select>
				</v-flex>
				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1" v-if="[7, 8, 10,13].includes(Number($route.params.id))">
					<v-autocomplete outlined dense :label="$store.getters.language.data.items.item_name" clearable :items="items" item-text="item_name" item-value="item_id" v-model="query.item_id"></v-autocomplete>
				</v-flex>

				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1">
					<v-btn block color="primary" @click="openReport()"> {{ $store.getters.language.data.search }} </v-btn>
				</v-flex>
				<v-flex xs12 md3 lg2 xl2 sm12 class="px-1">
					<v-btn block color="primary" @click="printReport()">
						<v-icon> mdi-printer </v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</v-container>

		<v-container>
			<v-layout row wrap>
				<!-- <v-flex class="only-print">
					<img :src="$image_url + 'file_attachments/serve_file/' + $store.getters.store.store_invoice_header" id="invoice-header" width="100%">
				</v-flex> -->
			</v-layout>
		</v-container>

		<v-container>
			<Expenses v-if="report_id == 1" :query="query" />
			<Incomes v-else-if="report_id == 2" :query="query" />
			<Accounts v-else-if="report_id == 3" :query="query" />
			<Loans v-else-if="report_id == 4" :query="query" />
			<Qist v-else-if="report_id == 5" :query="query" />
			<Invoices v-else-if="report_id == 6" :query="query" />
			<InvoiceItems v-else-if="report_id == 7" :query="query" />
			<InvoiceItemsTotal v-else-if="report_id == 8" :query="query" />
			<CustomersLoans v-else-if="report_id == 9" :query="query" />
			<Inventory v-else-if="report_id == 10" :query="query" />
			<Balance v-else-if="report_id == 11" :query="query" />
			<AllBalances v-else-if="report_id == 12" :query="query" />
			<Purchases v-else-if="report_id == 13" :query="query" />

		</v-container>
	</div>
</template>

<script>
	import Expenses from './Pages/Expenses.vue'
	import Incomes from './Pages/Incomes.vue'
	import Accounts from './Pages/Accounts.vue'
	import Loans from './Pages/Loans.vue'
	import Qist from './Pages/Qist.vue'
	import Invoices from './Pages/Invoices.vue'
	import InvoiceItems from './Pages/InvoiceItems.vue'
	import InvoiceItemsTotal from './Pages/InvoiceItemsTotal.vue'
	import CustomersLoans from './Pages/CustomersLoans.vue'
	import Inventory from './Pages/Inventory.vue'
	import Balance from './Pages/Balance.vue'
	import AllBalances from './Pages/AllBalances.vue'
	import Purchases from './Pages/Purchases.vue'

	export default {
		components: {
			Expenses,
			Incomes,
			Accounts,
			Loans,
			Qist,
			Invoices,
			InvoiceItems,
			InvoiceItemsTotal,
			CustomersLoans,
			Inventory,
			Balance,
			AllBalances,
			Purchases
		},
		data() {
			return {
				query: {
					from: new Date().toISOString().split('T')[0],
					to: new Date().toISOString().split('T')[0]
				},
				list: [
					{
						report_title: 'Expense',
						id: 1
					},
				],
				report_id: null,
				loading: false,
				banner: require('../../assets/wasl.jpg'),

			}
		},
		computed: {
			accounts() {
				return this.$store.getters.accounts_list
			},
			money_move_types() {
				return this.$store.getters.money_move_types_list
			},
			customers() {
				return this.$store.getters.customers_list
			},
			users() {
				return this.$store.getters.users_list
			},
			items() {
				return this.$store.getters.items_list
			},
			categories() {
				return this.$store.getters.categories_list
			}
		},
		mounted() {
			const isDark = localStorage.getItem('void_store_dark_theme')
			if (isDark && isDark == 'true') {
				this.$vuetify.theme.dark = true;
			} else {
				this.$vuetify.theme.dark = false;
			}

			window.matchMedia('print').addListener((mql) => {
				if (mql.matches) {
					this.$vuetify.theme.dark = false;
				} else {
					if (isDark && isDark == 'true') {
						this.$vuetify.theme.dark = true;
					}
				}
			})
		},
		methods: {
			openReport() {
				this.report_id = null
				setTimeout(() => {
					this.report_id = this.$route.params.id
				}, 250)
			},
			printReport() {
				var darkTheme = this.$vuetify.theme.dark
				if (darkTheme) {
					this.$vuetify.theme.dark = false;
				}
				setTimeout(() => {
					window.print()
					if (darkTheme) {
						this.$vuetify.theme.dark = true;
					}
				}, 100)
			}

		},
		watch: {
			'$route.params.id': function (value) {
				this.report_id = value
				this.query = {
					from: new Date().toISOString().split('T')[0],
					to: new Date().toISOString().split('T')[0]
				}
			}
		}
	}
</script>
